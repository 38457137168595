.wrapper {
	min-height: 305px;
	max-width: 1440px;
	margin: 0 auto;
	padding: 72px 80px 0 68px;

	@media screen and (max-width: 1300px) {
			padding: 72px 40px 0 28px;
	}
	@media screen and (max-width: 1195.5px) {
		max-width: 900px;
		padding: 72px 31px 0 19px;
	}
	@media screen and (max-width: 840px) {
		padding: 72px 19px 0 19px;
	}
	@media screen and (max-width: 650px) {
		padding: 72px 0 0 0;
		max-width: 411px;
	}
}

.spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 240px;
}

.test {
	width: 100%;
	margin-right: -12px;
}


@media screen and (max-width: 460px) {
	.wrapperBackground {
		height: 100%;
	}
}
